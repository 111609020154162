//
// dropzone.scss
//

.dropzone {
    border: 2px dashed $input-border-color;
    background: $input-bg;
    border-radius: 6px;
    cursor: pointer;
    min-height: 150px;
    // padding: 20px;
    display: flex;
    align-items: center;

    .dz-message {
        width: 100%;
        text-align: center;
        margin: 0 0;
        outline: none;
    }

    &.dz-started {
        .dz-message {
            display: none;
        }
    }
}


.dropzone-custom {
    // border: 2px dashed $input-border-color;
    background: $input-bg;
    border-radius: 6px;
    // cursor: pointer;
    // min-height: 150px;
    // max-height: 218px;
    // padding: 20px;
    // display: flex;
    // align-items: center;
    height: 100%;

    .dz-message {
        width: 100%;
        text-align: center;
        margin: 0 0;
        outline: none;
    }

    &.dz-started {
        .dz-message {
            display: none;
        }
    }
}

.dropzone-custom2 {
    // border: 2px dashed $input-border-color;
    background: $input-bg;
    border-radius: 6px;
    // cursor: pointer;
    // min-height: 150px;
    height: 100%;
    max-height: 317px;
    // padding: 20px;
    display: flex;
    align-items: center;

    .dz-message {
        width: 100%;
        text-align: center;
        margin: 0 0;
        outline: none;
    }

    &.dz-started {
        .dz-message {
            display: none;
        }
    }
}